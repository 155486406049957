<template>
  <app-center-container>
    <v-card outlined>
      <v-card-text>
        <div class="text-h5 text-center">Admin</div>
      </v-card-text>
    </v-card>
  </app-center-container>
</template>

<script>
export default {
  name: 'AdminView'
};
</script>
